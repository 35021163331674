import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";

const columns = [
  {
    field: "sno",
    headerName: "S_NO",
    width: 80,
    headerClassName: "custom-header",
    headerAlign: "center",
  },

  {
    field: "productName",
    headerName: "ProductName",
    width: 300,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "brand",
    headerName: "Brands",
    width: 200,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "barcode",
    headerName: "Barcode",
    width: 200,
    headerClassName: "custom-header",
    headerAlign: "center",
  },

  {
    field: "quantity",
    headerName: "Quantity",
    width: 150,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "model_number",
    headerName: "Model_number",
    width: 200,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
];

const LowStocks = ({ stocksLow }) => {
  // console.log("stocksLow", stocksLow)

  const [totalStocks, setTotalStocks] = useState([]);
  // console.log("toaljjf", totalStocks);

  useEffect(() => {
    const stocksDetails = stocksLow["ShortageStocks"];

    const productStocks = stocksDetails.map((pro, idx) => ({
      ...pro,
      _id: pro._id || uuidv4(),
      sno: idx + 1,
    }));
    setTotalStocks(productStocks);
  }, [stocksLow]);

  return (
    <>
      <div className=" p-1 rounded-md">
        <div className=" text-center underline text-rose-500">
          <h1 className="font-bold text-xl ">Shortage Stocks</h1>
        </div>
      </div>

      <div
        style={{ height: 500, width: "100%", overflow: "auto" }}
        className="p-4"
      >
        <DataGrid
          sx={{
            ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
            boxShadow: 2,
            border: 2,

            borderColor: "primary.light",
            "& .custom-header": {
              // fontSize: "h7.fontSize",
              fontWeight: "bold",
              fontSize: 16,
              color: "blue",
            },
            "& .MuiDataGrid-root, .MuiDataGrid-root ": {
              backgroundColor: "primary.main",
              color: "black",
            },
            "& .MuiDataGrid-colCell,  .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-sortIcon ": {
              backgroundColor: "red",
            },
            ".MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          rows={totalStocks}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[5, 15]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </div>
    </>
  );
};

export default LowStocks;
