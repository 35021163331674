import React, { useState } from "react";
import "reactjs-popup/dist/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomerInfo = ({
  customerForm,
  onChangeHandle,
  onSubmitForm,
  error,
}) => {
  const [gstDetails, setGstDetails] = useState();


  const handleDateChange = (date) => {
    onChangeHandle({
      target: {
        name: 'selectedDate', 
        value: date,
      },
    });
  };

  const toggleGst = () => {
    setGstDetails(!gstDetails);
  };

  return (
    <>
      <div class="w-full flex p-3">
        <div className="w-full sm:border border-gray-400 md:rounded-lg">
          <form className=" flex flex-col" onSubmit={onSubmitForm}>
            <div className="flex flex-wrap">
              <div className=" flex flex-col items-start m-2 w-full sm:w-auto">
                <label
                  className="text-sm mr-3 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  for="name"
                >
                  Customer Name
                </label>
                <input
                  class="flex h-10 w-full sm:w-60 md:w-80 rounded-md border mt-3 border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600"
                  type="text"
                  placeholder="Enter Customer name"
                  id="name"
                  name="customerName"
                  value={customerForm.customerName}
                  onChange={onChangeHandle}
                />
                <div>
                  <p className="text-start text-red-600  font-normal">
                    *{error.customerName}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start m-2 w-full sm:w-auto">
                <label
                  class="text-sm  mr-3 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  htmlFor="number"
                >
                  Contact Number
                </label>
                <input
                  class="flex h-10  w-full sm:w-60 md:w-80 rounded-md border mt-3 border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600"
                  type="number"
                  id="number"
                  placeholder="Enter Contact Number"
                  name="customerNumber"
                  value={customerForm.customerNumber}
                  onChange={onChangeHandle}
                />
                <div>
                  <p className="text-start text-red-600 font-medium">
                    *{error.customerNumber}{" "}
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-start m-2 w-full sm:w-auto">
                <label
                  class="text-sm  mr-3 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  htmlFor="number"
                >
                  Employee Code
                </label>
                <input
                  class="flex h-10 w-full sm:w-60 md:w-60 rounded-md border mt-3 border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600"
                  type="text"
                  id="billDate"
                  name="employeeId"
                  value={customerForm.employeeId}
                  onChange={onChangeHandle}
                />
              </div>
              <div>
                <button
                  className="bg-gray-200 rounded-md p-2 mt-7"
                  onClick={() => toggleGst()}
                >
                  GST+
                </button>
              </div>
              <div className="flex justify-end p-6">
                <DatePicker
                  // selected={date}
                  // onChange={(date) => setDate(date)}
                  selected={customerForm.selectedDate}
                  onChange={handleDateChange}
                />
              </div>
            </div>

            <div className="flex flex-col items-start m-2 mt-3 w-full sm:w-auto">
              <label
                class="text-sm  font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="address"
              >
                Billing Address
              </label>
              <textarea
                className=" flex rounded-md border border-black/30 bg-transparent px-3 py-2 text-sm  mt-3
               placeholder:text-gray-600 w-full sm:w-6/12 h-20"
                type="textarea"
                id="address"
                rows=""
                cols=""
                placeholder="Enter Customer Address"
                name="customerAddress"
                value={customerForm.customerAddress}
                onChange={onChangeHandle}
              ></textarea>
              <div>
                <p className="text-start text-red-600 font-medium">
                  *{error.customerAddress}{" "}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="bg-gray-300 rounded absolute top-20 right-16 ">
        {gstDetails && (
          <>
            <div className="flex flex-col items-start m-2 w-full sm:w-auto">
              <label
                class="text-sm  mr-3 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="number"
              >
                Customer GST Number
              </label>
              <input
                class="flex h-10 w-full sm:w-60 md:w-60 rounded-md border mt-3 border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600"
                type="text"
                id="gstNumber"
                name="gstNumber"
                value={customerForm.gstNumber}
                onChange={onChangeHandle}
                placeholder="Enter GST Number"
              />
            </div>
            <div className="flex flex-col items-start m-2 w-full sm:w-auto">
              <label
                class="text-sm  mr-3 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="number"
              >
                Company Name
              </label>
              <input
                class="flex h-10 w-full sm:w-60 md:w-60 rounded-md border mt-3 border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600"
                type="text"
                id="gstNumber"
                name="gstNumber"
                value={customerForm.gstNumber}
                onChange={onChangeHandle}
                placeholder="Enter Company Name"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CustomerInfo;
