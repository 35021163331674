import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { DataGrid } from "@mui/x-data-grid";
// import { Button } from '@mui/material';
import { FaEdit, FaTrash } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

const StoreSales = ({ storeSalesData, store_id }) => {

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterProductList, setFilterProductList] = useState([]);
  const [salesStocks, setSalesStocks] = useState([])
  console.log("sales", salesStocks)



  useEffect(() => {
    const salesReport = storeSalesData["Sales"];
    const totalSalesStocks= salesReport.map((sales, idx) => ({
        ...sales,
        _id: sales._id || uuidv4(),
    }))
    setSalesStocks(totalSalesStocks)
    setFilterProductList(totalSalesStocks)
  }, [storeSalesData])

  const handleDelete = (billNo) => {
    console.log(`Delete row with BillNo: ${billNo}`);
  };

  const columns = [
    // {
    //   field: "BillNo",
    //   headerName: "ID",
    //   width: 70,
    //   headerClassName: "custom-header",
    //   headerAlign: "center",
    // },
    {
      field: "EmpId",
      headerName: "Emplyee ID",
      width: 120,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 130,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "datas",
      headerName: "ProductName",
      width: 280,
      headerClassName: "custom-header",
      headerAlign: "center",

      valueGetter: (params) => {
        if (
          params.row.datas &&
          Array.isArray(params.row.datas) &&
          params.row.datas.length > 0
        ) {
          const barcodes = params.row.datas.map(
            (dataItem) => dataItem.productName
          );
          return barcodes.join("\n,\n ");
        } else {
          return "No Data";
        }
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      width: 130,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "subtotal",
      headerName: "Total Amt",
      width: 120,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "gst",
      headerName: "GST",
      type: "number",
      width: 80,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "customerAddress",
      headerName: "Adress",
      sortable: false,
      width: 160,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "totalAmtWithoutGst",
      headerName: "NetPrice",
      width: 130,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "custom-header",
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEdit(params.row)}
        >
          <FaEdit />
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "custom-header",

      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(params.row.BillNo)}
        >
          <FaTrash />
        </Button>
      ),
    },
  ];

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setEditedProduct({ ...product });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedProduct(null);
    setEditedProduct(null);
  };

  const handleSaveEdit = async () => {
    try {
      const jwtToken = Cookies.get("jwt_token");
      const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/editProduct/${store_id}/${selectedProduct._id}`;
      const option = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(editedProduct),
      };
      const response = await fetch(url, option);
      // console.log(response);
      if (response.ok) {
        console.log("Product updated successfully");
      } else {
        console.error("Failed to update product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleChange = (field, value) => {
    setEditedProduct((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

 
  const handleFilterData = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const regex = new RegExp(searchQuery, "i"); // 'i' flag for case-insensitive matching

    const filterData = salesStocks.filter((product) => {
      return (
        regex.test(product.customerName) ||
        regex.test(product.BillNo) ||
        regex.test(product.productName) ||
        regex.test(product.EmpId)
      );
    });
    setFilterProductList(filterData);
  }, [salesStocks, searchQuery]);


  return (
    <>
      <div className="flex  justify-between mb-2">
        <div>
    <h1 className="ml-10 text-lg font-semibold">State: {storeSalesData.state}</h1>
        </div>
        <div >
        <h1 className="text-xl font-bold mb-4 unde text-rose-600">Sales-Reports </h1>

        </div>

        <input
          type="search"
          id="search"
          className="h-8 w-60  mr-5 rounded-md border border-black bg-transparent  text-sm placeholder:text-gray-600"
          onChange={handleFilterData}
          placeholder="Search Sales data"
        />
        
      </div>
      
      <div style={{ height: 450, width: "100%", overflow: "auto" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .custom-header": {
              backgroundColor: "primary.main",
              color: "black",
            },
            "& .MuiDataGrid-colCell,  .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-sortIcon ": {
              backgroundColor: "red",
            },
            ".MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          // rows={storeSalesData.Sales}
          rows={filterProductList}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[5, 15]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </div>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {editedProduct && (
            <>
              <TextField
                label="Product Name"
                value={editedProduct.productName}
                onChange={(e) => handleChange("productName", e.target.value)}
                fullWidth
              />
              {/* Add text fields for other fields as needed */}
              <TextField
                label="Brand"
                value={editedProduct.brand}
                onChange={(e) => handleChange("brand", e.target.value)}
                fullWidth
              />
              <TextField
                label="Model_number"
                value={editedProduct.model_number}
                onChange={(e) => handleChange("model_number", e.target.value)}
                fullWidth
              />
              {/* <TextField
                label="Barcode"
                value={editedProduct.barcode}
                onChange={(e) => handleChange("barcode", e.target.value)}
                fullWidth
              />
              <TextField
                label="Barcode"
                value={editedProduct.quantity}
                onChange={(e) => handleChange("quantity", e.target.value)}
                fullWidth
              /> */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StoreSales;
