import React, { useState } from "react";
import Cookies from "js-cookie";

const AddStocks = ({  store_id }) => {
  // console.log("selected_stores_id", store_id);
  const initialFormData = {
    barcode: "",
    productName: "",
    brand: "",
    model_number: "",
    quantity: "",
    landingCost: "",
    gst: "",
    cutoffprice: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  console.log("formdata", formData)
  const [error, setError] = useState(initialFormData);

  const ValidationForm = () => {
    let err = {};

    if (formData.barcode === "") {
      err.barcode = "Name is required";
    }
    if (formData.productName === "") {
      err.productName = "Product_Name is required";
    }
    if (formData.brand === "") {
      err.brand = "Brand is required";
    }
    if (formData.model_number === "") {
      err.model_number = "Model_number is required";
    }
    if (formData.quantity === "") {
      err.quantity = "quantity is required";
    }
    if (formData.landingCost === "") {
      err.landingCost = "LandingCost is required";
    }
    if (formData.gst === "") {
      err.gst = "GST is required";
    }
    if (formData.cutoffprice === "") {
      err.cutoffprice = "cutoffprice is required";
    }

    setError({ ...err });
    return Object.keys(err).length === 0;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue =
      name === "mrp" ||
      name === "quantity" ||
      name === "gst" ||
      name === "cutoffprice" ||
      name === "landingCost"
        ? parseFloat(value)
        : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormData(initialFormData);
    let isvalid = ValidationForm();
    if (isvalid) {
      const jwtToken = Cookies.get("jwt_token");
      const url = "https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/addStocksData";
      const requestData = { ...formData, store_id };
      const option = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(requestData),
      };
      try {
        console.log(url + " url");
        const response = await fetch(url, option);
        const data = await response.json();
        console.log("responsedata", data);
        console.log("check", JSON.stringify(data));

        console.log(response);
      } catch {
        //onSubmitFailure();
      }
    } else {
      alert("all data required");
    }
  };

  return (
    <>
      <div className=" bg-slate-200 h-[100vh] sm:h-[100vh] ">
        <div className=" flex flex-col w-full items-center justify-center">
          <h1 className="mt-5 text-lg font-bold mb-4 bg-gradient-to-r from-cyan-500 to-blue-500 p-1 rounded-lg text-white w-36">
            Add Products
          </h1>
          <form
            className="w-full  flex flex-col  items-center"
            onSubmit={handleSubmit}
          >
            <div className="flex mb-2 mt-4 space-x-4 w-8/12">
              <div className="w-full">
                <label htmlFor="barcode" className="">
                  Bar Code:
                </label>
                <br />
                <input
                  type="text"
                  id="barcode"
                  name="barcode"
                  value={formData.barcode}
                  placeholder="Enter Barcode"
                  onChange={handleInputChange}
                  className=" border-black/30  px-3 py-2 text-sm placeholder:text-gray-600   rounded-lg w-full"
                />
                <div>
                  <p className="text-start text-red-600 font-medium">
                    *{error.barcode}
                  </p>
                </div>
              </div>

              <div className="w-full">
                <label htmlFor="productName">Product Name: </label>
                <input
                  className="px-3 py-2  text-sm placeholder:text-gray-600   rounded-lg w-full"
                  type="text"
                  id="productName"
                  name="productName"
                  value={formData.productName}
                  placeholder="Enter Product Name"
                  onChange={handleInputChange}
                />
                <div>
                  <p className="text-start text-red-600 font-medium">
                    *{error.productName}
                  </p>
                </div>
              </div>

              <div className="w-full">
                <label htmlFor="barcode" className="">
                  Quantity:
                </label>{" "}
                <br />
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  value={formData.quantity}
                  placeholder="Enter quantity"
                  onChange={handleInputChange}
                  className=" border-black/30  px-3 py-2 text-sm placeholder:text-gray-600   rounded-lg w-full"
                />
                <div>
                  <p className="text-start text-red-600 font-medium">
                    *{error.quantity}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex mb-2 mt-3 space-x-4 w-8/12">
              <div className="w-full">
                <label htmlFor="brand">Brand:</label>
                <input
                  className="px-3 py-2  text-sm placeholder:text-gray-600   rounded-lg w-full"
                  type="text"
                  id="brand"
                  name="brand"
                  value={formData.brand}
                  placeholder="Enter Brand"
                  onChange={handleInputChange}
                />
                <div>
                  <p className="text-start text-red-600 font-medium">
                    *{error.brand}
                  </p>
                </div>
              </div>

              <div className="w-full">
                <label htmlFor="model_number">Model number:</label>
                <input
                  className=" px-3 py-2  text-sm placeholder:text-gray-600  rounded-lg w-full"
                  type="text"
                  id="model_number"
                  name="model_number"
                  value={formData.model_number}
                  placeholder="Enter Model Number"
                  onChange={handleInputChange}
                />
                <div>
                  <p className="text-start text-red-600 font-medium">
                    *{error.model_number}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex mb-6 mt-3 space-x-4 w-8/12">
              <div className="w-full">
                <label htmlFor="landingCost">Landing Cost: </label>
                <input
                  className=" px-3 py-2  text-sm placeholder:text-gray-600  rounded-lg w-full"
                  type="text"
                  id="landingCost"
                  name="landingCost"
                  value={formData.landingCost}
                  placeholder="Enter Landing Cost"
                  onChange={handleInputChange}
                />
                <div>
                  <p className="text-start text-red-600 font-medium">
                    *{error.landingCost}
                  </p>
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="gst">GST: </label>
                <input
                  className=" px-3 py-2  text-sm placeholder:text-gray-600  rounded-lg w-full"
                  type="text"
                  id="gst"
                  name="gst"
                  value={formData.gst}
                  placeholder="Enter GST"
                  onChange={handleInputChange}
                />
                <div>
                  <p className="text-start text-red-600 font-medium">
                    *{error.gst}
                  </p>
                </div>
              </div>

              <div className="w-full">
                <label htmlFor="cutoffprice">Cut off Price: </label>
                <input
                  className=" px-3 py-2  text-sm placeholder:text-gray-600  rounded-lg w-full"
                  type="text"
                  id="cutoffprice"
                  name="cutoffprice"
                  value={formData.cutoffprice}
                  placeholder="Enter Cutoff Price"
                  onChange={handleInputChange}
                />
                <div>
                  <p className="text-start text-red-600 font-medium">
                    *{error.cutoffprice}
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-1 rounded-lg w-24 mt-2 text-white">
              <button type="submit" className="font-medium text-lg ">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddStocks;
