import React, {  useEffect, useState } from "react";
import Table from "../Dashboard/FormSection/Table";
import Cookies from "js-cookie";
import FormCreation from "../Dashboard/FormSection/FormCreation";

const Home = () => {
  const [data, setData]= useState('')
  // console.log("data from info", data)
  const [productList, setProductList] = useState([]);


  const fetchData = async () => {
    const jwtToken = Cookies.get("jwt_token");

    const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/getStoreData`;
    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const response = await fetch(url, option);
      //   console.log(response);

      if (response.status === 200) {
        const data = await response.json();
        setData(data)
        setProductList(data["Products"]);
        // setSalesData(data['Sales'])
        // localStorage.setItem("productList", JSON.stringify(data["Products"]));
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
        <FormCreation />
        <Table data={productList} setFilterdata={setProductList} info= {data} />
    </div>
  );
};

export default Home;
