import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaShoppingBag,
    FaThList,
    FaStoreAlt,
  } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import Cookies from "js-cookie";

  const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  // console.log("ispoen", isOpen)
  const navigate = useNavigate();

  const onClickLogout = () => {
    const jwtToken = Cookies.remove("jwt_token");
    console.log(jwtToken)
    localStorage.clear("token")
    navigate("/");
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    // console.log("Toggle state:", !isOpen);
  };

  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <FaTh />,
    },

    {
      path: "/billing",
      name: "Billing",
      icon: <FaUserAlt />,
    },

    {
      path: "/stocks",
      name: "Stocks List",
      icon: <FaThList />,
    },
    {
      path: "/sales",
      name: "Sales Stocks",
      icon: <FaStoreAlt />,
    },
    {
      path: "/product",
      name: "Add Products",
      icon: <FaShoppingBag />,
    },
    
  ];


  return (
    <>
      <div className="flex">
        <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
          <div className="top_section pt-5 pb-6 pl-2">
            <h1 style={{ display: isOpen ? "block" : "none" }} className="logo font-semibold text-lg underline text-red-700">
              G-digital
            </h1>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px"  }}
              className="bars"    onClick={() => {
                console.log("Bars clicked");
                toggleSidebar();
              }}
            >
               <FaBars />
            </div>
          </div>

          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className=" link flex items-center"
              activeclassname="active"
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text text-lg"
              >
                {item.name}
              </div>
            </NavLink>
          ))}

          
        </div>
        <div className="flex flex-col show_page">
          <Navbar onClickLogout={onClickLogout} />
          <main className="">{children}</main>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
