import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FormCreation = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });


  return (
    <>
      <div className="p-2 md:w-full  sm:w-auto ">
        <div className="flex justify-between">
          <div className="flex flex-col">
          <p className="sm:text-sm md:text-lg"> Time : {date.toLocaleTimeString()}</p>
          <p className="sm:text-sm  md:text-lg block md:hidden"> Date : {date.toLocaleDateString()}</p>
          </div>

          <div className="flex gap-4">
            
            <Link to={'/dashboard'}><button className="bg-gradient-to-r from-rose-500 to-blue-500 p-1 rounded-lg md:w-40  text-white text-lg  font-medium "  >Dashboard</button></Link>
            {/* <Link to={'/product'}><button className="bg-gradient-to-r from-rose-500 to-blue-500 p-1 rounded-lg w-24 text-white text-lg font-medium"  >Products</button></Link> */}
          </div>

            <div className="flex">
            <p className="mr-10 text-lg hidden md:block "> Date : {date.toLocaleDateString()}</p>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCreation;
