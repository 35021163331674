import React, { useRef } from "react";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import image1 from "../../../Components/Gdigital _Eaglelogo.png";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";
import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';

const Invoice = ({
    info,
    invoiceBillGenrate,
    setInvoiceBillGenrate,
    customerForm,
    date,
    submittedData,
    datas,
    totalCalGst,
    netPrice,
    total,
    paymentMode,
    transactionId,
    totalAmtWithoutGst,
    gst,
    subtotal,
  }) => {
    const componentRef = useRef();

    const saveAsImage = () => {
      html2canvas(componentRef.current).then(canvas => {
        canvas.toBlob(blob => {
          saveAs(blob, customerForm.customerName);
        });
      });
    };
  
    const showTime = date.getHours() 
    + ':' + date.getMinutes() 
    + ":" + date.getSeconds();
  
    const generateAndUploadPDF = async () => {
      try {
        const canvas = await html2canvas(componentRef.current);
        const pdfBlob = await new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to generate PDF blob."));
            }
          });
        });
        // console.log("blob", pdfBlob)
        const formData = new FormData();
        formData.append("pdf", pdfBlob, "invoice.png");
  
        const jwtToken = Cookies.get("jwt_token");
        const url = "https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/pdfupload";
        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            // "Content-Type": "application/pdf",
          },
          body: formData, 
        };
  
        const response = await fetch(url, options);
        if (response.ok) {
          const data = await response.json();
          // console.log(data)
        } else {
          console.error("Failed to upload PDF");
        }
      } catch (error) {
        console.error("Error while uploading PDF:", error);
      }
    };
  

  return (
    <>
     <div className=" md:w-[3in] sm:w-[3in] lg:w-[3in] xs:w-[3in] w-full h-full text-xs">
        <div className=" w-full h-full flex items-center justify-center ">
          {invoiceBillGenrate && (
            <div className=" p-2 absolute top-0 md:top-[-50px] left-0 bg-[#e6e5e48a] w-full h-full flex items-center justify-center">
              <div className="w-full md:w-[3in] xs:w-[3in] sm:w-[3in] bg-white p-2 text-black ">
                <div className="text-right">
                  <button onClick={() => setInvoiceBillGenrate(false)}>
                    <Link to={"/store"}>
                      <AiOutlineClose />
                    </Link>
                  </button>
                </div>

                <div
                  className="md:w-[3.1in] lg:w-[3.1in] xs:w-[3in] p-1"
                  ref={componentRef}
                >
                  <div className="flex  items-center justify-center mb-2">
                    <img className="w-10 rounded-lg " src={image1} alt="" />
                  </div>
                  <div>
                    <h4 className=" font-medium text-center text-[10px]">
                      Invoice Bill
                    </h4>
                  </div>

                  <div className="flex flex-col text-xs sm:w-auto ">
                    <h4 className="font-bold text-center text-[7px]">
                      G-Digital, {info.Name}
                    </h4>
                    <p className="text-[8px] w-[2.8in] font-medium text-center">
                      {info.Address}
                    </p>
                    <p className="text-[8px] font-medium text-center">
                      Contact : {info.Contact}
                    </p>
                    <p className="text-[8px] font-medium text-center">
                      GST : {info.GST}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <div className=" flex flex-col text-start  ">
                      <h1 className="font-medium text-[10px]">
                        Customer Information
                      </h1>
                      <div className="flex justify-center">
                        <div className="w-[210px]">
                          <p className="text-left font-medium flex items-center text-[8px]">
                            <span className=" font-medium text-[8px] mr-1 w-[30px]">
                              Name :
                            </span>
                            {customerForm.customerName}
                          </p>
                          <p className="text-left font-medium flex items-center text-[8px]">
                            <span className=" font-medium text-[8px] mr-1 w-[35px]">
                              Contact :
                            </span>
                            {customerForm.customerNumber}
                          </p>
                          <p className="text-left font-medium flex text-[8px]">
                            <span className="font-medium text-[8px] mr-1 w-[40px]">
                              Address :
                            </span>
                            <div className="w-[140px]">
                              {customerForm.customerAddress}
                            </div>
                          </p>
                        </div>
                          <div className="sm:text-xs w-[100px] font-medium pr-7 -ml-7 text-left">
                          <p className="   text-[8px] ">
                           Date : {date.toLocaleDateString()} <br/>
                            Time: {showTime}
                          </p>
                          <h1 className=" font-medium  text-[6.5px]">
                            Bill No : GD/24-25/{submittedData["Bill No"]}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-2 ">
                      <div className="pr-5 ">
                        <div className=" md:rounded-sm ">
                          <table
                            className="w-[3in] text-xs border-collapse border border-black "
                            style={{ width: "100%" }}
                          >
                            <thead className="bg-white w-[3in] ">
                              <tr>
                                <th
                                  className="text-center max-w-[6px] text-[8px] text-black border border-black  "
                                  id="product"
                                  style={{ width: "35%" }}
                                >
                                  Name
                                </th>
                                <th
                                  className="text-[7px] text-center   text-black  border border-black"
                                  style={{  width: "13%", overflowWrap: "break-word"}}
                                >
                                S.No
                                </th>
                                <th
                                  className="text-[7px]   text-black  border border-black"
                                  style={{ width: "8%" }}
                                >
                                  Qty
                                </th>

                                <th
                                  className="text-[7px] text-center   text-black border border-black "
                                  style={{ width: "12%" }}
                                >
                                  MRP
                                </th>
                                <th
                                  className="text-[7px] text-center   text-black  border border-black"
                                  style={{ width: "8%" }}
                                >
                                  GST
                                </th>
                                <th
                                  className="text-[7px] text-center   text-black  border border-black"
                                  style={{ width: "12%" }}
                                >
                                  Price
                                </th>

                                <th
                                  className="text-[7px]    text-black  border border-black"
                                  style={{ width: "10%" }}
                                >
                                  Discount
                                </th>
                                <th
                                  className="text-[7px]  text-black border border-black "
                                  style={{ width: "10%" }}
                                >
                                  Total(Rs)
                                </th>
                              </tr>
                            </thead>
                            <tbody className=" bg-white text-black border-red-600">
                              {datas.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      id="product"
                                      className="text-[7px] text-center font-bold  text-black border border-black"
                                    >
                                      {item.productName}
                                    </td>
                                    <td className="text-[7px] text-center font-bold text-black  border border-black"
                                    style={{width: "5%", wordWrap: "break-word"}}>
                                      {item.serialNumber}
                                    </td>
                                    <td className="text-[9px] text-center  font-bold text-black border border-black">
                                      {item.quantity}
                                    </td>
                                    <td className="text-[9px] text-center font-bold text-black border border-black">
                                      {item.mrp1}
                                    </td>
                                    <td className="text-[9px] text-center font-bold text-black  border border-black">
                                      {item.gst}
                                    </td>
                                    <td className="text-[9px] text-center font-bold text-black border border-black">
                                      {parseInt(netPrice[index])}
                                    </td>
                                    <td className="text-[9px] text-center font-bold text-black border border-black">
                                      {item.discount}
                                    </td>
                                    <td className="text-[9px] text-center font-bold text-black border border-black">
                                      {total[index]}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-start items-start mt-2">
                      <p>
                        <span className="text-left text-[10px] font-bold">
                          Payment Mode : {paymentMode}
                        </span>
                        <div className="flex flex-col text-[10px] ">
                          <p className="">{transactionId}</p>
                        </div>
                      </p>
                    </div>
                    <div>
                      <div
                        className="flex flex-col text-[5px] items-end justify-end border-lg p-2 mr-3 space-x-3"
                        style={{ textAlign: "right" }}
                      >
                        <div className="text-[10px] font-bold">
                          Subtotal : Rs{" "}
                          {parseFloat(totalAmtWithoutGst).toFixed(2)}
                        </div>

                        <div className=" text-[10px] font-bold">
                          GST : Rs {parseFloat(gst).toFixed(2)}
                        </div>
                        <div className="text-[10px] font-bold">
                          Total : Rs {parseFloat(subtotal).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h1 className="text-center mb-2 text-sm font-medium">
                      Thank You
                    </h1>
                  </div>
                </div>
                <ReactToPrint
                  pageStyle="@media print {
                      @page {
                       size: 3in ;
                       margin: 1;
                       font-size: 1pt
                    }
                      }"
                  trigger={() => (
                    <button className="bg-blue-500 text-sm p-1 rounded-lg w-24 text-center">
                      Print
                    </button>
                  )}
                  content={() => componentRef.current}
                  onBeforeGetContent={() => {
                    generateAndUploadPDF();
                  }}
                />
                <button className="bg-blue-500 ml-3 text-sm p-1 rounded-lg w-24 text-center" onClick={saveAsImage}>Save as PNG</button>

              </div>
            </div>
          )}
        </div>
      </div>
    
    </>
  )
}

export default Invoice