import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

const Sales = () => {
  const [salesData, setSalesData] = useState([]);
  // console.log("sales", salesData);
  // const [expandedRows, setExpandedRows] = useState([]);
  // const sales= [...salesData]
  // // console.log("data", sales)

  // const handleRowExpand = (rowId) => {
  //   console.log("rowId", rowId)
  //   setExpandedRows((prevExpandedRows) =>
  //     prevExpandedRows.includes(rowId)
  //       ? prevExpandedRows.filter((id) => id !== rowId)
  //       : [...prevExpandedRows, rowId]
  //   );
  // };
  const columns = [
    // {
    //   field: "expand",
    //   headerName: "expand",
    //   width: 120,
    //   sortable: false,
    //   renderCell: (params) => {
    //     console.log("first", params.row)
    //     return (
    //       <button
    //         onClick={() => handleRowExpand(params.row.BillNo)}
    //       >
    //         {expandedRows.includes(params.row.BillNo) ?  "Expand": "Collapse" }
    //       </button>
    //     );
    //   },
    // },
    {
      field: "BillNo",
      headerName: "BillNo",
      width: 70,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "EmpId",
      headerName: "Empl_ID",
      type: "number",
      width: 120,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "customerName",
      headerName: "Cust_Name",
      width: 120,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "customerAddress",
      headerName: "Cust_Address",
      width: 130,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "customerNumber",
      headerName: "Cust_Number",
      width: 120,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "subtotal",
      headerName: "Total_Amt",
      width: 130,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      headerClassName: "custom-header",
      headerAlign: "center",
    },

    {
      field: "gst",
      headerName: "GST(Rs)",
      type: "number",
      width: 110,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "datas",
      headerName: "ProductName",
      width: 350,
      headerClassName: "custom-header",
      headerAlign: "center",

      valueGetter: (params) => {
        if (
          params.row.datas &&
          Array.isArray(params.row.datas) &&
          params.row.datas.length > 0
        ) {
          // const firstDataItem = params.row.datas[0];
          // const barcode = firstDataItem.barcode;
          // return barcode;

          const barcodes = params.row.datas.map(
            (dataItem) => dataItem.productName
          );
          return barcodes.join("\n,\n ");

          // const allNestedData = params.row.datas.map((dataItem, index) => {
          //   return `
          // Entry ${index + 1}:
          //   Barcode: ${dataItem.barcode},
          //   Brand: ${dataItem.brand},
          //   Cutoff Price: ${dataItem.cutoffprice},
          //   Discount: ${dataItem.discount},
          //   GST: ${dataItem.gst},
          //   Landing Cost: ${dataItem.landingCost},
          //   Model Number: ${dataItem.model_number},
          //   MRP: ${dataItem.mrp},
          //   Product Name: ${dataItem.productName},
          //   Quantity: ${dataItem.quantity},
          //   Sno: ${dataItem.sno}`;
          // });
          // console.log(allNestedData.join());
          // return allNestedData.join();
        } else {
          return "No Data";
        }
      },
    },

    {
      field: "paymentMode",
      headerName: "Barcode",
      width: 150,
      headerClassName: "custom-header",
      headerAlign: "center",
      valueGetter: (params) => {
        if (
          params.row.datas &&
          Array.isArray(params.row.datas) &&
          params.row.datas.length > 0
        ) {
          const barcodes = params.row.datas.map((dataItem) => dataItem.barcode);
          return barcodes.join("\n,\n ");
        } else {
          return "No Data";
        }
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
      headerClassName: "custom-header",
      headerAlign: "center",
      valueGetter: (params) => {
        if (
          params.row.datas &&
          Array.isArray(params.row.datas) &&
          params.row.datas.length > 0
        ) {
          const barcodes = params.row.datas.map(
            (dataItem) => dataItem.quantity
          );
          return barcodes.join("\n,\n ");
        } else {
          return "No Data";
        }
      },
    },
  ];


  const fetchData = async () => {
    const jwtToken = Cookies.get("jwt_token");
    const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/getStoreData`;
    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const response = await fetch(url, option);
      if (response.status === 200) {
        const data = await response.json();
        setSalesData(data["Sales"]);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);




  return (
    <>
      <div>
        <div className="m-2 flex justify-end ">
          <div className="gap-2 ">
            <Link to={"/dashboard"}>
              <button className=" mr-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  p-1 rounded-lg w-40 text-white text-lg  ">
                Dashboard
              </button>
            </Link>
          </div>
        </div>
        <div className="underline text-rose-500">
          <h1 className="font-bold text-xl ">Sales Reports</h1>
        </div>
        <div
          style={{ height: 500, width: "100%", overflow: "auto" }}
          className="p-4"
        >
          <DataGrid
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .custom-header": {
                // fontSize: "h7.fontSize",
                fontWeight: "bold",
                fontSize: 16,
                color: "blue",
              },
              "& .MuiDataGrid-root, .MuiDataGrid-root ": {
                backgroundColor: "primary.main",
                color: "black",
              },
              "& .MuiDataGrid-colCell,  .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-sortIcon ": {
                backgroundColor: "red",
              },
              ".MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            rows={salesData}
            columns={columns}
            getRowId={(row) => row.BillNo}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[5, 15]}
            checkboxSelection
            disableRowSelectionOnClick
          />
      
        </div>
      </div>
    </>
  );
};

export default Sales;
