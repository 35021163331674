import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

const columns = [
  {
    field: "sno",
    headerName: "ID",
    width: 70,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "productName",
    headerName: "ProductName",
    width: 280,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "brand",
    headerName: "Brands",
    width: 130,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "barcode",
    headerName: "Barcode",
    width: 160,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  // {
  //   field: "mrp",
  //   headerName: "MRP",
  //   width: 120,
  //   headerClassName: "custom-header",
  //   headerAlign: "center",
  // },
  {
    field: "quantity",
    headerName: "Quantity",
    width: 100,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "landingCost",
    headerName: "LandingCost",
    width: 120,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "gst",
    headerName: "GST%",
    type: "number",
    width: 100,
    headerClassName: "custom-header",
    headerAlign: "center",
  },

  {
    field: "model_number",
    headerName: "Model_number",
    width: 150,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  // {
  //   field: "cutoffprice",
  //   headerName: "Cutoff_Price",
  //   width: 130,
  //   headerClassName: "custom-header",
  //   headerAlign: "center",
  // },
];

const Stocks = () => {
  const [stocksData, setStocksData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterProductList, setFilterProductList] = useState([]);

  const fetchData = async () => {
    const jwtToken = Cookies.get("jwt_token");

    const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/getStoreData`;
    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const response = await fetch(url, option);
      if (response.status === 200) {
        const data = await response.json();
        console.log(data);
        setStocksData(data["Products"]);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFilterData = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const regex = new RegExp(searchQuery, "i"); // 'i' flag for case-insensitive matching

    const filterData = stocksData.filter((product) => {
      return (
        regex.test(product.brand) ||
        regex.test(product.barcode) ||
        regex.test(product.productName)
      );
    });
    setFilterProductList(filterData);
  }, [stocksData, searchQuery]);

  /*this function is for downloading the table data in excel format  */

  // const prepareDownload = () => {
  //   const csvHeaders = columns.map((column) => column.headerName);
  //   const csvRows = [csvHeaders.join(",")];
  //   csvRows.push(...stocksData.map((row) => Object.values(row).join(",")));
  //   const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
  //   const encodedUri = encodeURI(csvContent);
  //   const link = document.createElement("a");
  //   link.setAttribute("href", encodedUri);
  //   link.setAttribute("download", "stocks.csv");
  //   document.body.appendChild(link);
  //   link.click();
  // };

  return (
    <>
      <div className="gap-2 text-right mt-2 mr-2">
        <Link to={"/dashboard"}>
          <button className=" mr-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  p-1 rounded-lg w-40 text-white text-lg  ">
            Dashboard
          </button>
        </Link>
      </div>

      <div className=" p-1 rounded-md">
        <div className=" text-center underline text-rose-500">
          <h1 className="font-bold text-xl ">Stocks Details</h1>
        </div>
        <div className="flex  justify-end">
          <input
            type="search"
            id="search"
            className="h-8 w-60  mr-5 rounded-md border border-black bg-transparent  text-sm placeholder:text-gray-600"
            onChange={handleFilterData}
            placeholder="Search Products"
          />
        </div>
      </div>

      <div
        style={{ height: 500, width: "100%", overflow: "auto" }}
        className="p-4"
      >
        <DataGrid
          sx={{
            ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
            boxShadow: 2,
            border: 2,

            borderColor: "primary.light",
            "& .custom-header": {
              // fontSize: "h7.fontSize",
              fontWeight: "bold",
              fontSize: 16,
              color: "blue",
            },
            "& .MuiDataGrid-root, .MuiDataGrid-root ": {
              backgroundColor: "primary.main",
              color: "black",
            },
            "& .MuiDataGrid-colCell,  .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-sortIcon ": {
              backgroundColor: "red",
            },
            ".MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          rows={filterProductList}
          columns={columns}
          getRowId={(row) => row?.sno}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[5, 15]}
          checkboxSelection
          disableRowSelectionOnClick
        />
        {/* this is button for download the stocks */}

        {/* <Button
          onClick={prepareDownload}
          variant="contained"
          color="primary"
          style={{ margin: "10px" }}
        >
          Download Stocks
        </Button> */}
      </div>
    </>
  );
};

export default Stocks;
