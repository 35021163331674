import Cookies from "js-cookie";
import React, { Fragment } from "react";
import { Outlet, Navigate } from "react-router";

const ProtectedRoutes = ({ children }) => {
//   let { loading, isAuthenticated=true } = useSelector((state) => state.user);
  // if(JSON.parse(localStorage.getItem('user_info')).isAuthenticated) {
  //   isAuthenticated = JSON.parse(localStorage.getItem('user_info')).isAuthenticated;
  // }
  // console.log(isAuthenticated);
  const isAuthenticated = Cookies.get("jwt_token");
  // console.log("isAuthenticated", isAuthenticated)

//   const token = localStorage.getItem("token")
//   console.log(token)


  if (!isAuthenticated) {
    return <Navigate to={"/"} replace />;
  }
  return <Fragment>{ children ? children : <Outlet />}</Fragment>;


     
};

export default ProtectedRoutes;