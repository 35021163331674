import React from "react";
import { FaTimes } from "react-icons/fa";
import { useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const CreateUser = () => {
  const [submittedData, setSubmittedData] = useState({});
  console.log("first", submittedData)
  
  const navigate = useNavigate()
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      empid: document.getElementById("empid").value,
      username: document.getElementById("username").value,
      password: document.getElementById("password").value,
      usertype: document.getElementById("usertype").value,
      storename: document.getElementById("storename").value,
      storeCode: document.getElementById("storeCode").value,
    };

    const jwtToken = Cookies.get("jwt_token");
    console.log(jwtToken);
    const url = "https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/createUsersAccount";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      setSubmittedData(data);
    } catch (error) {
      console.error("Error while sending data to the backend:", error);
    }
  };
  const setTotalStocksOpen= () =>{
    navigate('/admin')
  }

  return (
    <>
      <div className="flex flex-col h-[86vh]  rounded bg-zinc-200">
        <div className="flex justify-end  p-2">
          <FaTimes
            className="text-rose-600 cursor-pointer "
              onClick={() => setTotalStocksOpen()}
          />
        </div>
        <h1 className="text-xl text-center font-bold underline mb-5">
          Add New User
        </h1>
        <form
          className="w-full flex flex-col items-center"
          onSubmit={handleFormSubmit}
        
        >
          <div className="flex mb-2 mt-2 space-x-4" >
          <div className="w-80 flex flex-col">
              <label htmlFor="empid">Employee ID</label>
              <input
                type="text"
                className="rounded-lg form-control"
                id="empid"
              />
            </div>
            <div className="w-80 flex flex-col">
              <label htmlFor="username">username</label>
              <input
                type="text"
                className="rounded-lg form-control"
                id="username"
              />
            </div>
            </div>

        <div className="flex mb-2 mt-2 space-x-4" >
            <div className="w-80 flex flex-col">
              <label htmlFor="password">Password</label>
              <input
                type="text"
                className="form-control rounded-lg"
                id="password"
              />
            </div>
            <div className="w-80 flex flex-col">
              <label htmlFor="usertype">Usertype</label>
              <input
                type="text"
                className="form-control rounded-lg"
                id="usertype"
              />
            </div>
            </div>

            <div className="flex mb-2 mt-2 space-x-4" >

            <div className="w-80 flex flex-col">
              <label htmlFor="storename">storename</label>
              <input
                type="text"
                className="form-control rounded-lg"
                id="storename"
              />
            </div>
            <div className="w-80 flex flex-col">
              <label htmlFor="storeCode">storeCode</label>
              <input
                type="text"
                className="form-control rounded-lg"
                id="storeCode"
              />
            </div>
            </div>
        
            <button className="bg-blue-500 p-2 mt-8 text-white rounded-xl w-20 mb-2">
              Submit
            </button>
            
         
        </form>
      </div>
    </>
  );
};

export default CreateUser;
