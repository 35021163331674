import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaStore } from "react-icons/fa";
import { Link } from "react-router-dom";

const AdminDashboard = ({ children }) => {
  const [data, setData] = useState("");
  console.log("data", data);

  const fetchData = async () => {
    const jwtToken = Cookies.get("jwt_token");
    const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/getAllStoreData`;
    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const response = await fetch(url, option);
      if (response.status === 200) {
        const data = await response.json();
        setData(data);
        // setStocksData(data[0]["Products"]);
        // setSalesData(data[0]["Sales"]);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    <div className=" bg-gray-300 p-2 min-h-[100vh]">
      <div className="gap-2 text-right mt-2 mr-2">
        <Link to={"/dashboard"}>
          <button className=" mr-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  p-1 rounded-lg w-40 text-white text-lg  ">
            Dashboard
          </button>
        </Link>
      </div>

      <div className="flex justify-around mt-2 p-2">
        <Link to={"/CreateStore"}>
          <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#31C8C2] to-[#1AABCB] shadow-lg w-[228px] shadow-gray-400 h-24">
            <button className="text-xl font-semibold">Create Stores</button>
            <FaStore size={64} className=" cursor-pointer" />
          </div>
        </Link>

        <Link to={"/createuser"}>
          <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#31C8C2] to-[#1AABCB] shadow-lg w-[228px] shadow-gray-400 h-24">
            <button className="text-xl font-semibold">Create Users</button>
            <FaStore size={64} className=" cursor-pointer" />
          </div>
        </Link>

        <Link to={"/store"}>
          <div className="flex text-white m-2 justify-between p-3 rounded-lg bg-gradient-to-r from-[#31C8C2] to-[#13c8f1] shadow-lg w-[228px] shadow-gray-400 h-24">
            <button className="text-xl font-semibold">Store Details</button>
            <FaStore size={64} className=" cursor-pointer" />
          </div>
        </Link>
      </div>
      {/* <ul>
        {data.map(item => (
          <li key={item.name}>
            <h3>{item.name}'s Products:</h3>
            <ul>
              {item.Products.map(product => (
                <li key={product.barcode}>
                  Product ID: {product.barcode}, Product brand: {product.brand}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul> */}
</div>
    </>
  );
};

export default AdminDashboard;
