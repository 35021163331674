import React from "react";
import {  Link,  } from "react-router-dom";
import "../../App.css";
import image1 from "../Gdigital _Eaglelogo.png";


const Navbar = ({onClickLogout}) => {

  return (
    <>
      <div className="flex h-17  justify-between  w-full bg-black p-1">
        
        <img
          className="xs:w-[50px] w-[50px] lg:w-[60px] rounded-md mx-4 lg:mx-6"
          src={image1}
          alt="logo"
        />
        <h1 className="text-white font-semibold  xs:text-xl md:text-3xl">Billing-Software</h1>
        <ul className="flex items-center justify-center">
          <li className=" list-none">
            <Link
              to="/"
              onClick={onClickLogout}
              className="bg-gradient-to-r from-rose-500 to-blue-500  rounded-lg w-15 sm:w-20 md:w-24 h-10  text-white text-lg font-medium pl-5 pr-5 pt-2 pb-2 ml-5  text-center  cursor-pointer"
            >
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
